import { default as _91_46_46_46pageSlug_93RdkgWmojczMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activateM87eBInYXAMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue?macro=true";
import { default as indexDBwDGZ6WKXMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue?macro=true";
import { default as _91securityKey_93V6qg0lRu3PMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newa0Mr6cMjX6Meta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue?macro=true";
import { default as watchlist91iSGDyXwcMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue?macro=true";
import { default as indexcAZ5ErnRN2Meta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue?macro=true";
import { default as editTxN8xvFUCTMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as index3jF66riEOkMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addRn8NPyczAtMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesxGo2E4vVv1Meta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as faqyz6mmXnAZ9Meta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as infoslE0gLduBCMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_931iVSZ4us4rMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programukjKSgIn18Meta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopV8zxRtMDqTMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93B16CMoQPSHMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as _91showSlug_93euyaxCBaklMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchersIgqwmH9ti1Meta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93miHHVX19jPMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexAiiz78xFtNMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_938flHMpmnAxMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemas5bnnKrjaCyMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiessXtszYsYc8Meta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue?macro=true";
import { default as indexeSGUka4WUSMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_93xnWWK5D6LuMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexAMShrv6t0pMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_930vxW8lCo9wMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresy2wf1Ayl4lMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexV5X67Pyvx7Meta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue?macro=true";
import { default as index9KzejUiCrfMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93WHRbgoB5McMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsslLor26loxMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue?macro=true";
import { default as searchr5bnXm8fISMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue?macro=true";
import { default as vouchersq5U2ylhETrMeta } from "/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexcAZ5ErnRN2Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editTxN8xvFUCTMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index3jF66riEOkMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addRn8NPyczAtMeta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesxGo2E4vVv1Meta || {},
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_938flHMpmnAxMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_930vxW8lCo9wMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93WHRbgoB5McMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/dev/2025-01-24_11-24-44_8179b0a/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]